import React, { useEffect, lazy, Suspense } from "react";
import techbanner from "../assets/images/tech-banner.png";
import { ITServicesConstants } from "../constants/ITServices";
import { usePostHog } from 'posthog-js/react';
// Lazy load the image component
const LazyImage = lazy(() => import('../components/LazyImage'));

function ITServices() {
  const posthog = usePostHog();
  posthog.capture('page_viewed', { page: 'IT Services' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" mx-auto px-4 py-4">
      <div className="text-white">
        <div className="mx-auto text-center">
          <img
            src={techbanner}
            alt="logo"
            className="h-96 w-full object-cover"
          />
        </div>
      </div>
      <div className=" mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {ITServicesConstants.map((service, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-500 hover:-translate-y-5 hover:shadow-xxl"
            >
              <div className="flex flex-col h-full">
                <div className="h-0 pb-[80%] relative">
                  <Suspense fallback={<div className="w-full h-full bg-gray-200 animate-pulse"></div>}>
                    <LazyImage
                      src={service.image}
                      alt={service.title}
                      className="absolute top-0 left-0 w-full h-full object-cover mt-4 z-1"
                    />
                  </Suspense>
                </div>
                <div className="p-6 flex flex-col flex-grow">
                  <h2 className="text-xl font-semibold mb-2">
                    {service.title}
                  </h2>
                  <p className="text-gray-600 flex-grow">
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ITServices;
