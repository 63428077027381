import React, { useState, useEffect } from "react";
import banner3 from "../assets/images/banner3.jpg";
import reverseEngineeringImage from "../assets/images/products/ReverseEngineering.png"; // Import the image
import { ProductCategories } from "../constants/WaterPumpSolutions";
import { PumpSpares } from "../constants/PumpSpares";
import { usePostHog } from 'posthog-js/react';

function Products() {
  const [activeTab, setActiveTab] = useState("pumps");
  const posthog = usePostHog();
  posthog.capture('page_viewed', { page: 'Water Pump Solutions' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-auto px-4 py-4">
      <div className="relative text-white mb-8">
        <div className="mx-auto text-center">
          <img src={banner3} alt="logo" className="h-32 w-full object-cover" />
        </div>
        <div className="absolute inset-0 flex mt-10 mx-20">
          <h1 className="text-4xl font-bold font-titillium text-white shadow-text capitalize">
            WATER PUMP SOLUTIONS
          </h1>
        </div>
      </div>

      <div className="mb-12">
        <div className="flex justify-center">
          <div className="inline-flex rounded-md shadow-sm" role="group">
            <button
              className={`px-8 py-3 text-sm font-medium border border-gray-200 rounded-l-lg focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-700 transition-all duration-200 ${
                activeTab === "pumps"
                  ? "bg-blue-50 text-blue-700"
                  : "bg-white text-gray-700 hover:text-gray-900 hover:bg-gray-100"
              }`}
              onClick={() => setActiveTab("pumps")}
            >
              Pumps
            </button>
            <button
              className={`px-8 py-3 text-sm font-medium border border-gray-200 rounded-r-lg focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-700 transition-all duration-200 ${
                activeTab === "spares"
                  ? "bg-blue-50 text-blue-700"
                  : "bg-white text-gray-700 hover:text-gray-900 hover:bg-gray-100"
              }`}
              onClick={() => setActiveTab("spares")}
            >
              Pump Spares
            </button>
            <button
              className={`px-8 py-3 text-sm font-medium border border-gray-200 rounded-r-lg focus:z-10 focus:ring-2 focus:ring-blue-500 focus:text-blue-700 transition-all duration-200 ${
                activeTab === "reverse"
                  ? "bg-blue-50 text-blue-700"
                  : "bg-white text-gray-700 hover:text-gray-900 hover:bg-gray-100"
              }`}
              onClick={() => setActiveTab("reverse")}
            >
              Reverse Engineering
            </button>
          </div>
        </div>
      </div>

      {activeTab === "pumps" && (
        <div className="container mx-auto py-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {ProductCategories.map((service, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-500 hover:-translate-y-5 hover:shadow-xxl"
              >
                <div className="flex flex-col ">
                  <div className="aspect-w-9 aspect-h-8">
                    <img src={service.image} alt={service.name} />
                  </div>
                  <div className="p-4 mt-4 flex flex-col flex-grow bg-djmTextColor items-center justify-center">
                    <p className="text-lg font-semibold  text-center">
                      {service.name.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === "spares" && (
        <div className="container mx-auto py-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {PumpSpares.map((service, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-500 hover:-translate-y-5 hover:shadow-xxl"
              >
                <div className="flex flex-col ">
                  <div className="aspect-w-9 aspect-h-8">
                    <img src={service.image} alt={service.name} />
                  </div>
                  <div className="p-4 mt-4 flex flex-col flex-grow bg-djmTextColor items-center justify-center">
                    <p className="text-lg font-semibold text-center font-titillium">
                      {service.name.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === "reverse" && (
        <div className="container mx-auto py-8 px-4 bg-gray-50 rounded-lg shadow-md font-titillium">
          <h3 className="text-3xl  mb-8 text-gray-800 border-b-2 border-blue-500 pb-2 inline-block font-titillium">
            Reverse Engineering Solutions
          </h3>
          <div className="space-y-6 mb-8">
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-1">
                <svg
                  className="w-5 h-5 text-blue-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className="ml-4 text-gray-700 leading-relaxed font-titillium">
                Reverse engineering makes it possible to quickly reproduce pump
                spare parts that are hard to find or no longer available.
              </p>
            </div>
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-1">
                <svg
                  className="w-5 h-5 text-blue-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className="ml-4 text-gray-700 leading-relaxed font-titillium">
                You are in urgent need of a spare part to keep your system
                running. However, the manufacturer may no longer offer spare
                parts. The delivery period may be too long. Or you may wish to
                replace parts with new components with an improved structural or
                hydraulic design or higher-grade materials. In all these
                situations, HIT-G is your partner of choice for reverse
                engineering solutions - for valves, pumps and other rotating
                equipment.
              </p>
            </div>
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-1">
                <svg
                  className="w-5 h-5 text-blue-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className="ml-4 text-gray-700 leading-relaxed ">
                Whether the part to be replaced is a turned part, a casting, a
                milled or welded component: thanks to reverse engineering the
                reproduction of spare parts is straightforward - regardless of
                the make. All you have to do is provide HIT-G with the exact
                dimensions or a sample of the part to be replaced. Even badly
                worn parts can often be reproduced. Pumps for water applications
                or boiler feed pumps in particular can benefit from retrofit
                solutions from HIT-G engineering services.
              </p>
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <div className="w-full max-w-3xl h-[400px] relative overflow-hidden rounded-lg shadow-md">
              <img
                src={reverseEngineeringImage}
                alt="Reverse Engineering Process"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Products;
