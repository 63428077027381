import { Carousel, Typography } from "@material-tailwind/react";
import techbanner from "../assets/images/tech.jpg";
import b2 from "../assets/images/banner1.jpg";
import { useEffect } from "react";
import AboutUs from "./AboutUs";
import { usePostHog } from 'posthog-js/react';

function Home() {
  const posthog = usePostHog();
  posthog.capture('page_viewed', { page: 'Home' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <div className="mx-auto px-4 py-4">
          
          <Carousel
        autoPlay={true}
        loop={true}
        autoplayDelay={1000}
        transition={{ type: "tween", duration: 0.5 }}
        className="h-[24rem]"
      >
       

        <div className="relative h-full w-full">
          <img
            src={techbanner}
            alt="tech"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 mt-20 grid  w-full place-items-center">
            <div className="text-center ">
              <Typography
                variant="h1"
                color="white"
                className="text-3xl md:text-4xl lg:text-5xl font-titillium"
              >
                YOUR PARTNER IN DIGITAL EXCELLENCE
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="mb-12 opacity-80 font-titillium"
              >
                Streamlined Solutions for Complex IT Challenges
              </Typography>
            </div>
          </div>
              </div>
              
              <div className="relative h-full w-full">
          <img src={b2} alt="world" className="h-full w-full object-cover"  />
          <div className="absolute inset-0 mt-20 grid w-full place-items-center">
            <div className="text-center ">
              <Typography
                variant="h1"
                color="white"
                className="text-3xl md:text-4xl lg:text-5xl font-titillium"
              >
                PREMIUM PUMPING SOLUTIONS FOR YOUR INDUSTRY
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className="mb-12 opacity-80 font-titillium"
              >
                Custom-tailored products for different industries
              </Typography>
            </div>
          </div>
        </div>
          </Carousel>
          

        <AboutUs />

    </div>
  );
}

export default Home;
